.cmp-card-statistic {
	padding: 15px 20px;
	
	.icon {
		align-items: center;
		border-radius: 8px;
		display: flex;
		height: 46px;
		justify-content: center;
		width: 46px;
		
		img {
			height: 32px;
			object-fit: contain;
			width: 32px;
		}
	}
	
	@media (max-width: $mediaLg) {
		.h2 {
			font-size: 26px;
		}
	}
}
