.btn {
	align-items: center;
	border-radius: 6px;
	color: $colorText;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	line-height: 1;
	position: relative;
	transition: background .15s;
	
	.icon {
		height: 18px;
		width: 18px;
	}
	
	&-accent {
		background: $colorAccent;
		color: #fff !important;
		
		.icon {
			* {
				fill: #fff;
			}
		}
		
		&:hover {
			background: darken($colorAccent, 3%);
		}
	}
	
	&-primary {
		background: $colorPrimary;
		color: #fff !important;
		
		.icon {
			* {
				fill: #fff;
			}
		}
		
		&:hover {
			background: darken($colorPrimary, 3%);
		}
	}
	
	&-white {
		background: #fff;
	}
	
	&-border {
		border: 1px solid #dbdde3;
		
		.icon {
			* {
				fill: $colorText;
			}
		}
	}
	
	&-disable {
		background: #dbdde3 !important;
		color: $colorText !important;
	}
	
	&-circle {
		border-radius: 100%;
		height: 36px;
		padding: 0;
		width: 36px;
	}
	
	&-xs {
		font-size: 13px;
		height: 28px;
		padding: 6px 15px;
	}
	
	&-sm {
		font-size: 15px;
		height: 36px;
		padding: 10px 20px 11px;
	}
	
	&-lg {
		font-size: 18px;
		height: 48px;
		padding: 16px 20px 14px;
	}
}

.btn-group {
	border-radius: 6px;
	box-shadow: inset 0 0 2px #dbdde3;
	display: flex;
}
