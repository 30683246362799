@import "./variables";

html {
	color: $colorText;
	font: 400 #{$fontSize}px/1.5 $fontName;
}

#main-wrap {
	&._lk {
		width: calc(100% - 240px);
		transition: all .2s;
		
		&._menu-opened {
			@media (min-width: $mediaXl) {
				width: calc(100% - 100px);
			}
			
			@media (max-width: $mediaXl) {
				transform: translate3d(240px, 0, 0);
			}
		}
		
		@media (max-width: $mediaXl) {
			width: 100%;
		}
	}
}

a {
	color: inherit;
	outline: none !important;
}

/* Position */
[class*="pos-abs"] {
	position: absolute;
}

.pos {
	position: relative;
	z-index: 1;
	
	&-abs {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	
	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}
	
	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* Image */
[class*="image-"] {
	height: 100%;
	width: 100%;
}

.image {
	&-cover {
		object-fit: cover;
	}
	
	&-contain {
		object-fit: contain;
	}
	
	&-circle {
		border-radius: 100%;
	}
}

/* Scroll */
[class*="scroll-"] {
	-webkit-overflow-scrolling: touch;
}

.scroll {
	&-y {
		overflow: hidden auto;
	}
	
	&-x {
		overflow: auto hidden;
	}
	
	&-disabled {
		overflow: hidden;
	}
}

/* Z index */
.z {
	&-1 {
		z-index: 1;
	}
	
	&-n1 {
		z-index: -1;
	}
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

