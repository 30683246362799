@import "../../../static/styles/inc/global/variables";

#lk-header {
	background: #fff;
	padding: 10px 35px;

	#logo {
		width: 80px;

		img {
			width: 100%;
		}
	}

	.language {
		position: relative;

		.selected {
			background: url("../../../static/imgs/icon/chevron-down.svg") 100% 50% no-repeat;
			background-size: 8px auto;
			cursor: pointer;
			padding-right: 15px;
		}

		.list {
			background: #fff;
			border-radius: 3px;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 100%;
			transform: translate3d(0, 20px, 0);
			transition: all .3s;
			visibility: hidden;
			width: 100%;

			&-item {
				cursor: pointer;
				font-size: 13px;
				padding: 1px 6px;

				&:hover {
					background: $colorAccent;
					color: #fff;
				}
			}
		}

		&._active {
			.list {
				opacity: 1;
				transform: translate3d(0, 5px, 0);
				visibility: visible;
			}
		}
	}

	.notification {
		.dot {
			background: $colorPink;
			border: 2px solid #fff;
			border-radius: 100%;
			height: 13px;
			position: absolute;
			right: -2px;
			top: -2px;
			width: 13px;
		}
	}

	.notification-list {
		position: absolute;
		top: calc(100% + 25px);
		right: 0;
		width: 340px;
		max-height: 175px;
		overflow: auto;
		transition: all .2s;
		opacity: 0;
		border: 1px solid #d7d7d7;
		border-radius: 5px;
		visibility: hidden;
		padding: 10px 12px;
		background: #fff;

		&._visible {
			top: calc(100% + -5px);
			opacity: 1;
			visibility: visible;
		}
	}

	.notification-popup {
		position: fixed;
		bottom: calc(0px + 20px);
		right: 40px;
		width: 200px;
		max-height: 175px;
		overflow: auto;
		transition: all .2s;
		opacity: 0;
		border: 1px solid #d7d7d7;
		border-radius: 5px;
		visibility: hidden;
		padding: 10px 12px;
		color: $colorWhite;

		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&._visible {
			bottom: calc(0px + 25px);
			opacity: .9;
			visibility: visible;
		}
	}

	.show {
		display: block;
	}

	.hide {
		display: none;
	}

	.profile {
		.avatar {
			border-radius: 100%;
			height: 36px;
			width: 36px;
		}

		.name {
			font-size: 16px;
			letter-spacing: 0.2px;
			max-width: 100px;
			@include text-overflow(1);
		}

		.link-list {
			background: #fff;
			opacity: 0;
			position: absolute;
			right: 0;
			top: calc(100% + 10px);
			transform: translate3d(0, 15px, 0);
			visibility: hidden;
			width: 120px;

			.link {
				color: $colorText;
				padding: 8px 12px;
				text-align: left;

				&:hover {
					background: $colorPrimary;
					color: #fff;
				}
			}

		}

		&._active {
			.link-list {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				visibility: visible;
			}
		}
	}

	@media (max-width: $mediaXxl) {
		padding: 10px 15px;
	}
}
