@import "../../../static/styles/inc/global/variables";

#auth-header {
	padding: 20px 0;
	
	#logo {
		width: 118px;
		
		img {
			width: 100%;
		}
	}
	
	#open-mobile-menu {
		height: 20px;
		width: 20px;
		
		span {
			background: $colorText;
			border-radius: 2px;
			display: block;
			height: 2px;
			width: 20px;
			
			&:before,
			&:after {
				background: $colorText;
				border-radius: 2px;
				content: "";
				height: 2px;
				left: 0;
				position: absolute;
				right: 0;
			}
			
			&:before {
				top: -6px;
			}
			
			&:after {
				bottom: -6px;
			}
		}
		
		&._active {
			span {
				background: transparent;
				
				&:before,
				&:after {
					top: 0 !important;
				}
				
				&:before {
					transform: rotate(45deg);
				}
				
				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
	
	.link {
		color: $colorText;
		font-size: 15px;
	}
	
	.language {
		position: relative;
		
		.selected {
			background: url("../../../static/imgs/icon/chevron-down.svg") 100% 50% no-repeat;
			background-size: 8px auto;
			cursor: pointer;
			padding-right: 15px;
		}
		
		.list {
			background: #fff;
			border-radius: 3px;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 100%;
			transform: translate3d(0, 20px, 0);
			transition: all .3s;
			visibility: hidden;
			width: 100%;
			
			&-item {
				cursor: pointer;
				font-size: 13px;
				padding: 1px 6px;
				
				&:hover {
					background: $colorAccent;
					color: #fff;
				}
			}
		}
		
		&._active {
			.list {
				opacity: 1;
				transform: translate3d(0, 5px, 0);
				visibility: visible;
			}
		}
	}
	
	.button {
		border: 1px solid $colorText2;
		border-radius: 8px;
		padding: 7px 26px 8px;
	}
	
	@media (max-width: $mediaXl) {
		padding: 15px 0;
	}
	
	@media (max-width: $mediaMd) {
		background: #fff;
		border-bottom: 1px solid #cacdd7;
		padding: 12.5px 0;
		
		#logo {
			width: 82px;
		}
	}
}
