.cmp-card_index-stat {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	.des {
		color: $colorText2;
		font-size: 13px;
		margin: 4px 0 2px;
	}
	
	.num {
		font-size: 32px;
		line-height: 1;
	}
	
	table {
		color: $colorText2;
		font-size: 13px;
		margin-top: 40px;
		white-space: nowrap;
		width: 100%;
		
		th {
			font-size: 11px;
			letter-spacing: 1px;
			padding: 3px 0;
			text-align: right;
			text-transform: uppercase;
		}
		
		td {
			padding: 3px 0;
		}
		
		._num {
			min-width: 60px;
			text-align: right;
		}
	}
}
