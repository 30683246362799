.tag {
	align-items: center;
	border-radius: 20px;
	display: inline-flex;
	font-size: 10px;
	justify-content: center;
	min-width: 72px;
	padding: 1px 15px 2px;
	white-space: nowrap;
	
	svg {
		height: 16px;
		margin-right: 4px;
		width: 16px;
	}
	
	&-primary {
		background: $colorPrimary;
		color: #fff;
		
		&-op {
			background: #eff7ff;
			color: $colorPrimary;
		}
	}
	
	&-green {
		background: $colorGreen;
		color: #fff;
		
		&-op {
			background: #effff1;
			color: $colorGreen;
		}
	}
}
