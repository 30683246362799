/* Font size */
$fontSize: 13;
$fontName: "IBM Plex Sans", Arial, sans-serif;

/* Colors */
$colorText: #304156;
$colorAccent: #d3153d;
$colorText2: #828d99;
$colorLink: #2992f3;
$colorBg: #f3f5f9;
$colorPink: #ff4a7a;
$colorPrimary: #3686ff;
$colorGreen: #00c292;
$colorWhite: #fff;

/* Functions */
@mixin text-overflow($lines) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
}

@mixin placeholder($color) {
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&:-ms-input-placeholder {
		color: $color;
	}
}

/* Media */
$mediaXxl: 1399.98px;
$mediaXl: 1199.98px;
$mediaLg: 991.98px;
$mediaMd: 767.98px;
$mediaSm: 575.98px;
$media475: 475.98px;
$media375: 375px;
$media320: 320px;
