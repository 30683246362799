.bg {
	&-bg {
		background: $colorBg !important;
	}
	
	&-wh {
		background: #fff !important;
	}
	
	&-link {
		color: $colorLink;
	}
}

.icon {
	&-white {
		* {
			fill: #fff;
		}
	}
}
