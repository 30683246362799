.cmp-card {
	background: #fff;
	border-radius: 6px;
	box-shadow: 1px 2px 4px rgba(67, 86, 100, 0.1);
	margin-bottom: 20px;
	overflow: hidden;
	padding: 20px;
	
	.card-title {
		font-size: 20px;
		line-height: 1.4;
	}
	
	&._no-shadow {
		box-shadow: unset;
	}
	
	&._gray-bg {
		background: #cacdd7;
	}
	
	@media (max-width: $mediaXl) {
		margin-bottom: 16px;
		padding: 15px !important;
	}
}

@import "./index-stat";
@import "./statistic";
@import "./footbal-vs";
@import "./event";
