//@font-face {
//	font-family: "IBM Plex Sans";
//	src: url("../../../fonts/IBMPlexSans-Bold.woff2") format("woff2"),
//	url("../../../fonts/IBMPlexSans-Bold.woff") format("woff");
//	font-weight: 700;
//	font-style: normal;
//	font-display: swap;
//}
//
//@font-face {
//	font-family: "IBM Plex Sans";
//	src: url("../../../fonts/IBMPlexSans-SemiBold.woff2") format("woff2"),
//	url("../../../fonts/IBMPlexSans-SemiBold.woff") format("woff");
//	font-weight: 600;
//	font-style: normal;
//	font-display: swap;
//}

@font-face {
	font-family: "IBM Plex Sans";
	src: url("../../../fonts/IBMPlexSans-Medium.woff2") format("woff2"),
	url("../../../fonts/IBMPlexSans-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IBM Plex Sans";
	src: url("../../../fonts/IBMPlexSans.woff2") format("woff2"),
	url("../../../fonts/IBMPlexSans.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IBM Plex Sans";
	src: url("../../../fonts/IBMPlexSans-Light.woff2") format("woff2"),
	url("../../../fonts/IBMPlexSans-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
