h1,
.h1 {
	font-size: 38px;
	line-height: 1.2;
}

h2,
.h2 {
	font-size: 32px;
	line-height: 1.2;
}

h3,
.h3 {
	font-size: 20px;
}

h4,
.h4 {
	font-size: 18px;
}

h5,
.h5 {
	font-size: 16px;
}

h6,
.h6 {
	font-size: 15px;
}

.h7 {
	font-size: 11px;
}

.f {
	/* Text case */
	&-upper {
		text-transform: uppercase;
	}
	
	&-lower {
		text-transform: lowercase;
	}
	
	/* Font weight */
	&-700 {
		font-weight: 700;
	}
	
	&-600 {
		font-weight: 600;
	}
	
	&-500 {
		font-weight: 500;
	}
	
	&-400 {
		font-weight: 400;
	}
	
	&-300 {
		font-weight: 300;
	}
}

/* Color */
.c {
	&-accent {
		color: $colorAccent;
	}
	
	&-text2 {
		color: $colorText2;
	}
	
	&-text {
		color: $colorText;
	}
	
	&-link {
		color: $colorLink;
	}
	
	&-red {
		color: red !important;
	}
}

/* Text align */
.ta {
	&-left {
		text-align: left;
	}
	
	&-center {
		text-align: center !important;
	}
	
	&-right {
		text-align: right !important;
	}
}
