/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--bs-gutter-x, 0.75rem);
	padding-right: var(--bs-gutter-x, 0.75rem);
	width: 100%;
}

@media (min-width: 1200px) {
	.clients__filters {
		.col-xl-3 {
			width: 33.3% !important;
		}

		.col-12 {
			width: calc(100% - 33.3%) !important;
		}

		.form-label {
			white-space: nowrap;
		}
	}
}

@media (min-width: 576px) {
	.container-sm, .container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container-md, .container-sm, .container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container-lg, .container-md, .container-sm, .container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container-xl, .container-lg, .container-md, .container-sm, .container {
		max-width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
		max-width: 1320px;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	margin-left: calc(var(--bs-gutter-x) * -.5);
	margin-right: calc(var(--bs-gutter-x) * -.5);
	margin-top: calc(var(--bs-gutter-y) * -1);
}

.row > * {
	box-sizing: border-box;
	flex-shrink: 0;
	margin-top: var(--bs-gutter-y);
	max-width: 100%;
	padding-left: calc(var(--bs-gutter-x) * .5);
	padding-right: calc(var(--bs-gutter-x) * .5);
	width: 100%;
}

.col {
	flex: 1 0 0;
}

.row-cols-auto > * {
	flex: 0 0 auto;
	width: auto;
}

.row-cols-1 > * {
	flex: 0 0 auto;
	width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 auto;
	width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.row-cols-4 > * {
	flex: 0 0 auto;
	width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 auto;
	width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

@media (min-width: 576px) {
	.col-sm {
		flex: 1 0 0;
	}
	
	.row-cols-sm-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	
	.row-cols-sm-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.row-cols-sm-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.row-cols-sm-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	
	.row-cols-sm-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.row-cols-sm-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	
	.row-cols-sm-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
}

@media (min-width: 768px) {
	.col-md {
		flex: 1 0 0;
	}
	
	.row-cols-md-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	
	.row-cols-md-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.row-cols-md-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.row-cols-md-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	
	.row-cols-md-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.row-cols-md-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	
	.row-cols-md-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
}

@media (min-width: 992px) {
	.col-lg {
		flex: 1 0 0;
	}
	
	.row-cols-lg-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	
	.row-cols-lg-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.row-cols-lg-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.row-cols-lg-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	
	.row-cols-lg-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.row-cols-lg-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	
	.row-cols-lg-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
}

@media (min-width: 1200px) {
	.col-xl {
		flex: 1 0 0;
	}
	
	.row-cols-xl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	
	.row-cols-xl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.row-cols-xl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.row-cols-xl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	
	.row-cols-xl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.row-cols-xl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	
	.row-cols-xl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
}

@media (min-width: 1400px) {
	.col-xxl {
		flex: 1 0 0;
	}
	
	.row-cols-xxl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	
	.row-cols-xxl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.row-cols-xxl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.row-cols-xxl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	
	.row-cols-xxl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.row-cols-xxl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	
	.row-cols-xxl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.col-1 {
	flex: 0 0 auto;
	width: 8.33333333%;
}

.col-2 {
	flex: 0 0 auto;
	width: 16.66666667%;
}

.col-3 {
	flex: 0 0 auto;
	width: 25%;
}

.col-4 {
	flex: 0 0 auto;
	width: 33.33333333%;
}

.col-5 {
	flex: 0 0 auto;
	width: 41.66666667%;
}

.col-6 {
	flex: 0 0 auto;
	width: 50%;
}

.col-7 {
	flex: 0 0 auto;
	width: 58.33333333%;
}

.col-8 {
	flex: 0 0 auto;
	width: 66.66666667%;
}

.col-9 {
	flex: 0 0 auto;
	width: 75%;
}

.col-10 {
	flex: 0 0 auto;
	width: 83.33333333%;
}

.col-11 {
	flex: 0 0 auto;
	width: 91.66666667%;
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

.offset-1 {
	margin-left: 8.33333333%;
}

.offset-2 {
	margin-left: 16.66666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.33333333%;
}

.offset-5 {
	margin-left: 41.66666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.33333333%;
}

.offset-8 {
	margin-left: 66.66666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.33333333%;
}

.offset-11 {
	margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
	--bs-gutter-x: 0;
}

.g-0,
.gy-0 {
	--bs-gutter-y: 0;
}

.g-1,
.gx-1 {
	--bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
	--bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
	--bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
	--bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
	--bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
	--bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
	--bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
	--bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
	--bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
	--bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}
	
	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	
	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	
	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	
	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	
	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	
	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	
	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	
	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	
	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	
	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.offset-sm-0 {
		margin-left: 0;
	}
	
	.offset-sm-1 {
		margin-left: 8.33333333%;
	}
	
	.offset-sm-2 {
		margin-left: 16.66666667%;
	}
	
	.offset-sm-3 {
		margin-left: 25%;
	}
	
	.offset-sm-4 {
		margin-left: 33.33333333%;
	}
	
	.offset-sm-5 {
		margin-left: 41.66666667%;
	}
	
	.offset-sm-6 {
		margin-left: 50%;
	}
	
	.offset-sm-7 {
		margin-left: 58.33333333%;
	}
	
	.offset-sm-8 {
		margin-left: 66.66666667%;
	}
	
	.offset-sm-9 {
		margin-left: 75%;
	}
	
	.offset-sm-10 {
		margin-left: 83.33333333%;
	}
	
	.offset-sm-11 {
		margin-left: 91.66666667%;
	}
	
	.g-sm-0,
	.gx-sm-0 {
		--bs-gutter-x: 0;
	}
	
	.g-sm-0,
	.gy-sm-0 {
		--bs-gutter-y: 0;
	}
	
	.g-sm-1,
	.gx-sm-1 {
		--bs-gutter-x: 0.25rem;
	}
	
	.g-sm-1,
	.gy-sm-1 {
		--bs-gutter-y: 0.25rem;
	}
	
	.g-sm-2,
	.gx-sm-2 {
		--bs-gutter-x: 0.5rem;
	}
	
	.g-sm-2,
	.gy-sm-2 {
		--bs-gutter-y: 0.5rem;
	}
	
	.g-sm-3,
	.gx-sm-3 {
		--bs-gutter-x: 1rem;
	}
	
	.g-sm-3,
	.gy-sm-3 {
		--bs-gutter-y: 1rem;
	}
	
	.g-sm-4,
	.gx-sm-4 {
		--bs-gutter-x: 1.5rem;
	}
	
	.g-sm-4,
	.gy-sm-4 {
		--bs-gutter-y: 1.5rem;
	}
	
	.g-sm-5,
	.gx-sm-5 {
		--bs-gutter-x: 3rem;
	}
	
	.g-sm-5,
	.gy-sm-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 768px) {
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}
	
	.col-md-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	
	.col-md-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	
	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.col-md-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	
	.col-md-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	
	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.col-md-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	
	.col-md-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	
	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	
	.col-md-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	
	.col-md-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	
	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.offset-md-0 {
		margin-left: 0;
	}
	
	.offset-md-1 {
		margin-left: 8.33333333%;
	}
	
	.offset-md-2 {
		margin-left: 16.66666667%;
	}
	
	.offset-md-3 {
		margin-left: 25%;
	}
	
	.offset-md-4 {
		margin-left: 33.33333333%;
	}
	
	.offset-md-5 {
		margin-left: 41.66666667%;
	}
	
	.offset-md-6 {
		margin-left: 50%;
	}
	
	.offset-md-7 {
		margin-left: 58.33333333%;
	}
	
	.offset-md-8 {
		margin-left: 66.66666667%;
	}
	
	.offset-md-9 {
		margin-left: 75%;
	}
	
	.offset-md-10 {
		margin-left: 83.33333333%;
	}
	
	.offset-md-11 {
		margin-left: 91.66666667%;
	}
	
	.g-md-0,
	.gx-md-0 {
		--bs-gutter-x: 0;
	}
	
	.g-md-0,
	.gy-md-0 {
		--bs-gutter-y: 0;
	}
	
	.g-md-1,
	.gx-md-1 {
		--bs-gutter-x: 0.25rem;
	}
	
	.g-md-1,
	.gy-md-1 {
		--bs-gutter-y: 0.25rem;
	}
	
	.g-md-2,
	.gx-md-2 {
		--bs-gutter-x: 0.5rem;
	}
	
	.g-md-2,
	.gy-md-2 {
		--bs-gutter-y: 0.5rem;
	}
	
	.g-md-3,
	.gx-md-3 {
		--bs-gutter-x: 1rem;
	}
	
	.g-md-3,
	.gy-md-3 {
		--bs-gutter-y: 1rem;
	}
	
	.g-md-4,
	.gx-md-4 {
		--bs-gutter-x: 1.5rem;
	}
	
	.g-md-4,
	.gy-md-4 {
		--bs-gutter-y: 1.5rem;
	}
	
	.g-md-5,
	.gx-md-5 {
		--bs-gutter-x: 3rem;
	}
	
	.g-md-5,
	.gy-md-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 992px) {
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}
	
	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	
	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	
	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	
	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	
	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	
	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	
	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	
	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	
	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	
	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.offset-lg-0 {
		margin-left: 0;
	}
	
	.offset-lg-1 {
		margin-left: 8.33333333%;
	}
	
	.offset-lg-2 {
		margin-left: 16.66666667%;
	}
	
	.offset-lg-3 {
		margin-left: 25%;
	}
	
	.offset-lg-4 {
		margin-left: 33.33333333%;
	}
	
	.offset-lg-5 {
		margin-left: 41.66666667%;
	}
	
	.offset-lg-6 {
		margin-left: 50%;
	}
	
	.offset-lg-7 {
		margin-left: 58.33333333%;
	}
	
	.offset-lg-8 {
		margin-left: 66.66666667%;
	}
	
	.offset-lg-9 {
		margin-left: 75%;
	}
	
	.offset-lg-10 {
		margin-left: 83.33333333%;
	}
	
	.offset-lg-11 {
		margin-left: 91.66666667%;
	}
	
	.g-lg-0,
	.gx-lg-0 {
		--bs-gutter-x: 0;
	}
	
	.g-lg-0,
	.gy-lg-0 {
		--bs-gutter-y: 0;
	}
	
	.g-lg-1,
	.gx-lg-1 {
		--bs-gutter-x: 0.25rem;
	}
	
	.g-lg-1,
	.gy-lg-1 {
		--bs-gutter-y: 0.25rem;
	}
	
	.g-lg-2,
	.gx-lg-2 {
		--bs-gutter-x: 0.5rem;
	}
	
	.g-lg-2,
	.gy-lg-2 {
		--bs-gutter-y: 0.5rem;
	}
	
	.g-lg-3,
	.gx-lg-3 {
		--bs-gutter-x: 1rem;
	}
	
	.g-lg-3,
	.gy-lg-3 {
		--bs-gutter-y: 1rem;
	}
	
	.g-lg-4,
	.gx-lg-4 {
		--bs-gutter-x: 1.5rem;
	}
	
	.g-lg-4,
	.gy-lg-4 {
		--bs-gutter-y: 1.5rem;
	}
	
	.g-lg-5,
	.gx-lg-5 {
		--bs-gutter-x: 3rem;
	}
	
	.g-lg-5,
	.gy-lg-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 1200px) {
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	
	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	
	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	
	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	
	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	
	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	
	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	
	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	
	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	
	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	
	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.offset-xl-0 {
		margin-left: 0;
	}
	
	.offset-xl-1 {
		margin-left: 8.33333333%;
	}
	
	.offset-xl-2 {
		margin-left: 16.66666667%;
	}
	
	.offset-xl-3 {
		margin-left: 25%;
	}
	
	.offset-xl-4 {
		margin-left: 33.33333333%;
	}
	
	.offset-xl-5 {
		margin-left: 41.66666667%;
	}
	
	.offset-xl-6 {
		margin-left: 50%;
	}
	
	.offset-xl-7 {
		margin-left: 58.33333333%;
	}
	
	.offset-xl-8 {
		margin-left: 66.66666667%;
	}
	
	.offset-xl-9 {
		margin-left: 75%;
	}
	
	.offset-xl-10 {
		margin-left: 83.33333333%;
	}
	
	.offset-xl-11 {
		margin-left: 91.66666667%;
	}
	
	.g-xl-0,
	.gx-xl-0 {
		--bs-gutter-x: 0;
	}
	
	.g-xl-0,
	.gy-xl-0 {
		--bs-gutter-y: 0;
	}
	
	.g-xl-1,
	.gx-xl-1 {
		--bs-gutter-x: 0.25rem;
	}
	
	.g-xl-1,
	.gy-xl-1 {
		--bs-gutter-y: 0.25rem;
	}
	
	.g-xl-2,
	.gx-xl-2 {
		--bs-gutter-x: 0.5rem;
	}
	
	.g-xl-2,
	.gy-xl-2 {
		--bs-gutter-y: 0.5rem;
	}
	
	.g-xl-3,
	.gx-xl-3 {
		--bs-gutter-x: 1rem;
	}
	
	.g-xl-3,
	.gy-xl-3 {
		--bs-gutter-y: 1rem;
	}
	
	.g-xl-4,
	.gx-xl-4 {
		--bs-gutter-x: 1.5rem;
	}
	
	.g-xl-4,
	.gy-xl-4 {
		--bs-gutter-y: 1.5rem;
	}
	
	.g-xl-5,
	.gx-xl-5 {
		--bs-gutter-x: 3rem;
	}
	
	.g-xl-5,
	.gy-xl-5 {
		--bs-gutter-y: 3rem;
	}
}

@media (min-width: 1400px) {
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	
	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	
	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	
	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	
	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	
	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	
	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	
	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	
	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	
	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	
	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	
	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	
	.offset-xxl-0 {
		margin-left: 0;
	}
	
	.offset-xxl-1 {
		margin-left: 8.33333333%;
	}
	
	.offset-xxl-2 {
		margin-left: 16.66666667%;
	}
	
	.offset-xxl-3 {
		margin-left: 25%;
	}
	
	.offset-xxl-4 {
		margin-left: 33.33333333%;
	}
	
	.offset-xxl-5 {
		margin-left: 41.66666667%;
	}
	
	.offset-xxl-6 {
		margin-left: 50%;
	}
	
	.offset-xxl-7 {
		margin-left: 58.33333333%;
	}
	
	.offset-xxl-8 {
		margin-left: 66.66666667%;
	}
	
	.offset-xxl-9 {
		margin-left: 75%;
	}
	
	.offset-xxl-10 {
		margin-left: 83.33333333%;
	}
	
	.offset-xxl-11 {
		margin-left: 91.66666667%;
	}
	
	.g-xxl-0,
	.gx-xxl-0 {
		--bs-gutter-x: 0;
	}
	
	.g-xxl-0,
	.gy-xxl-0 {
		--bs-gutter-y: 0;
	}
	
	.g-xxl-1,
	.gx-xxl-1 {
		--bs-gutter-x: 0.25rem;
	}
	
	.g-xxl-1,
	.gy-xxl-1 {
		--bs-gutter-y: 0.25rem;
	}
	
	.g-xxl-2,
	.gx-xxl-2 {
		--bs-gutter-x: 0.5rem;
	}
	
	.g-xxl-2,
	.gy-xxl-2 {
		--bs-gutter-y: 0.5rem;
	}
	
	.g-xxl-3,
	.gx-xxl-3 {
		--bs-gutter-x: 1rem;
	}
	
	.g-xxl-3,
	.gy-xxl-3 {
		--bs-gutter-y: 1rem;
	}
	
	.g-xxl-4,
	.gx-xxl-4 {
		--bs-gutter-x: 1.5rem;
	}
	
	.g-xxl-4,
	.gy-xxl-4 {
		--bs-gutter-y: 1.5rem;
	}
	
	.g-xxl-5,
	.gx-xxl-5 {
		--bs-gutter-x: 3rem;
	}
	
	.g-xxl-5,
	.gy-xxl-5 {
		--bs-gutter-y: 3rem;
	}
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.jc-start {
	justify-content: flex-start !important;
}

.jc-end {
	justify-content: flex-end !important;
}

.jc-center {
	justify-content: center !important;
}

.jc-between {
	justify-content: space-between !important;
}

.jc-around {
	justify-content: space-around !important;
}

.jc-evenly {
	justify-content: space-evenly !important;
}

.ai-start {
	align-items: flex-start !important;
}

.ai-end {
	align-items: flex-end !important;
}

.ai-center {
	align-items: center !important;
}

.ai-baseline {
	align-items: baseline !important;
}

.ai-stretch {
	align-items: stretch !important;
}

.ac-start {
	align-content: flex-start !important;
}

.ac-end {
	align-content: flex-end !important;
}

.ac-center {
	align-content: center !important;
}

.ac-between {
	align-content: space-between !important;
}

.ac-around {
	align-content: space-around !important;
}

.ac-stretch {
	align-content: stretch !important;
}

.as-auto {
	align-self: auto !important;
}

.as-start {
	align-self: flex-start !important;
}

.as-end {
	align-self: flex-end !important;
}

.as-center {
	align-self: center !important;
}

.as-baseline {
	align-self: baseline !important;
}

.as-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}

@media (min-width: 476px) {
	.d-xs-inline {
		display: inline !important;
	}
	
	.d-xs-inline-block {
		display: inline-block !important;
	}
	
	.d-xs-block {
		display: block !important;
	}
	
	.d-xs-grid {
		display: grid !important;
	}
	
	.d-xs-table {
		display: table !important;
	}
	
	.d-xs-table-row {
		display: table-row !important;
	}
	
	.d-xs-table-cell {
		display: table-cell !important;
	}
	
	.d-xs-flex {
		display: flex !important;
	}
	
	.d-xs-inline-flex {
		display: inline-flex !important;
	}
	
	.d-xs-none {
		display: none !important;
	}
	
	.flex-xs-fill {
		flex: 1 1 auto !important;
	}
	
	.flex-xs-row {
		flex-direction: row !important;
	}
	
	.flex-xs-column {
		flex-direction: column !important;
	}
	
	.flex-xs-row-reverse {
		flex-direction: row-reverse !important;
	}
	
	.flex-xs-column-reverse {
		flex-direction: column-reverse !important;
	}
	
	.flex-xs-grow-0 {
		flex-grow: 0 !important;
	}
	
	.flex-xs-grow-1 {
		flex-grow: 1 !important;
	}
	
	.flex-xs-shrink-0 {
		flex-shrink: 0 !important;
	}
	
	.flex-xs-shrink-1 {
		flex-shrink: 1 !important;
	}
	
	.flex-xs-wrap {
		flex-wrap: wrap !important;
	}
	
	.flex-xs-nowrap {
		flex-wrap: nowrap !important;
	}
	
	.flex-xs-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	
	.jc-xs-start {
		justify-content: flex-start !important;
	}
	
	.jc-xs-end {
		justify-content: flex-end !important;
	}
	
	.jc-xs-center {
		justify-content: center !important;
	}
	
	.jc-xs-between {
		justify-content: space-between !important;
	}
	
	.jc-xs-around {
		justify-content: space-around !important;
	}
	
	.jc-xs-evenly {
		justify-content: space-evenly !important;
	}
	
	.ai-xs-start {
		align-items: flex-start !important;
	}
	
	.ai-xs-end {
		align-items: flex-end !important;
	}
	
	.ai-xs-center {
		align-items: center !important;
	}
	
	.ai-xs-baseline {
		align-items: baseline !important;
	}
	
	.ai-xs-stretch {
		align-items: stretch !important;
	}
	
	.ac-xs-start {
		align-content: flex-start !important;
	}
	
	.ac-xs-end {
		align-content: flex-end !important;
	}
	
	.ac-xs-center {
		align-content: center !important;
	}
	
	.ac-xs-between {
		align-content: space-between !important;
	}
	
	.ac-xs-around {
		align-content: space-around !important;
	}
	
	.ac-xs-stretch {
		align-content: stretch !important;
	}
	
	.as-xs-auto {
		align-self: auto !important;
	}
	
	.as-xs-start {
		align-self: flex-start !important;
	}
	
	.as-xs-end {
		align-self: flex-end !important;
	}
	
	.as-xs-center {
		align-self: center !important;
	}
	
	.as-xs-baseline {
		align-self: baseline !important;
	}
	
	.as-xs-stretch {
		align-self: stretch !important;
	}
	
	.order-xs-first {
		order: -1 !important;
	}
	
	.order-xs-0 {
		order: 0 !important;
	}
	
	.order-xs-1 {
		order: 1 !important;
	}
	
	.order-xs-2 {
		order: 2 !important;
	}
	
	.order-xs-3 {
		order: 3 !important;
	}
	
	.order-xs-4 {
		order: 4 !important;
	}
	
	.order-xs-5 {
		order: 5 !important;
	}
	
	.order-xs-last {
		order: 6 !important;
	}
}

@media (min-width: 576px) {
	.d-sm-inline {
		display: inline !important;
	}
	
	.d-sm-inline-block {
		display: inline-block !important;
	}
	
	.d-sm-block {
		display: block !important;
	}
	
	.d-sm-grid {
		display: grid !important;
	}
	
	.d-sm-table {
		display: table !important;
	}
	
	.d-sm-table-row {
		display: table-row !important;
	}
	
	.d-sm-table-cell {
		display: table-cell !important;
	}
	
	.d-sm-flex {
		display: flex !important;
	}
	
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
	
	.d-sm-none {
		display: none !important;
	}
	
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	
	.flex-sm-row {
		flex-direction: row !important;
	}
	
	.flex-sm-column {
		flex-direction: column !important;
	}
	
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	
	.jc-sm-start {
		justify-content: flex-start !important;
	}
	
	.jc-sm-end {
		justify-content: flex-end !important;
	}
	
	.jc-sm-center {
		justify-content: center !important;
	}
	
	.jc-sm-between {
		justify-content: space-between !important;
	}
	
	.jc-sm-around {
		justify-content: space-around !important;
	}
	
	.jc-sm-evenly {
		justify-content: space-evenly !important;
	}
	
	.ai-sm-start {
		align-items: flex-start !important;
	}
	
	.ai-sm-end {
		align-items: flex-end !important;
	}
	
	.ai-sm-center {
		align-items: center !important;
	}
	
	.ai-sm-baseline {
		align-items: baseline !important;
	}
	
	.ai-sm-stretch {
		align-items: stretch !important;
	}
	
	.ac-sm-start {
		align-content: flex-start !important;
	}
	
	.ac-sm-end {
		align-content: flex-end !important;
	}
	
	.ac-sm-center {
		align-content: center !important;
	}
	
	.ac-sm-between {
		align-content: space-between !important;
	}
	
	.ac-sm-around {
		align-content: space-around !important;
	}
	
	.ac-sm-stretch {
		align-content: stretch !important;
	}
	
	.as-sm-auto {
		align-self: auto !important;
	}
	
	.as-sm-start {
		align-self: flex-start !important;
	}
	
	.as-sm-end {
		align-self: flex-end !important;
	}
	
	.as-sm-center {
		align-self: center !important;
	}
	
	.as-sm-baseline {
		align-self: baseline !important;
	}
	
	.as-sm-stretch {
		align-self: stretch !important;
	}
	
	.order-sm-first {
		order: -1 !important;
	}
	
	.order-sm-0 {
		order: 0 !important;
	}
	
	.order-sm-1 {
		order: 1 !important;
	}
	
	.order-sm-2 {
		order: 2 !important;
	}
	
	.order-sm-3 {
		order: 3 !important;
	}
	
	.order-sm-4 {
		order: 4 !important;
	}
	
	.order-sm-5 {
		order: 5 !important;
	}
	
	.order-sm-last {
		order: 6 !important;
	}
}

@media (min-width: 768px) {
	.d-md-inline {
		display: inline !important;
	}
	
	.d-md-inline-block {
		display: inline-block !important;
	}
	
	.d-md-block {
		display: block !important;
	}
	
	.d-md-grid {
		display: grid !important;
	}
	
	.d-md-table {
		display: table !important;
	}
	
	.d-md-table-row {
		display: table-row !important;
	}
	
	.d-md-table-cell {
		display: table-cell !important;
	}
	
	.d-md-flex {
		display: flex !important;
	}
	
	.d-md-inline-flex {
		display: inline-flex !important;
	}
	
	.d-md-none {
		display: none !important;
	}
	
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	
	.flex-md-row {
		flex-direction: row !important;
	}
	
	.flex-md-column {
		flex-direction: column !important;
	}
	
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	
	.jc-md-start {
		justify-content: flex-start !important;
	}
	
	.jc-md-end {
		justify-content: flex-end !important;
	}
	
	.jc-md-center {
		justify-content: center !important;
	}
	
	.jc-md-between {
		justify-content: space-between !important;
	}
	
	.jc-md-around {
		justify-content: space-around !important;
	}
	
	.jc-md-evenly {
		justify-content: space-evenly !important;
	}
	
	.ai-md-start {
		align-items: flex-start !important;
	}
	
	.ai-md-end {
		align-items: flex-end !important;
	}
	
	.ai-md-center {
		align-items: center !important;
	}
	
	.ai-md-baseline {
		align-items: baseline !important;
	}
	
	.ai-md-stretch {
		align-items: stretch !important;
	}
	
	.ac-md-start {
		align-content: flex-start !important;
	}
	
	.ac-md-end {
		align-content: flex-end !important;
	}
	
	.ac-md-center {
		align-content: center !important;
	}
	
	.ac-md-between {
		align-content: space-between !important;
	}
	
	.ac-md-around {
		align-content: space-around !important;
	}
	
	.ac-md-stretch {
		align-content: stretch !important;
	}
	
	.as-md-auto {
		align-self: auto !important;
	}
	
	.as-md-start {
		align-self: flex-start !important;
	}
	
	.as-md-end {
		align-self: flex-end !important;
	}
	
	.as-md-center {
		align-self: center !important;
	}
	
	.as-md-baseline {
		align-self: baseline !important;
	}
	
	.as-md-stretch {
		align-self: stretch !important;
	}
	
	.order-md-first {
		order: -1 !important;
	}
	
	.order-md-0 {
		order: 0 !important;
	}
	
	.order-md-1 {
		order: 1 !important;
	}
	
	.order-md-2 {
		order: 2 !important;
	}
	
	.order-md-3 {
		order: 3 !important;
	}
	
	.order-md-4 {
		order: 4 !important;
	}
	
	.order-md-5 {
		order: 5 !important;
	}
	
	.order-md-last {
		order: 6 !important;
	}
}

@media (min-width: 992px) {
	.d-lg-inline {
		display: inline !important;
	}
	
	.d-lg-inline-block {
		display: inline-block !important;
	}
	
	.d-lg-block {
		display: block !important;
	}
	
	.d-lg-grid {
		display: grid !important;
	}
	
	.d-lg-table {
		display: table !important;
	}
	
	.d-lg-table-row {
		display: table-row !important;
	}
	
	.d-lg-table-cell {
		display: table-cell !important;
	}
	
	.d-lg-flex {
		display: flex !important;
	}
	
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
	
	.d-lg-none {
		display: none !important;
	}
	
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	
	.flex-lg-row {
		flex-direction: row !important;
	}
	
	.flex-lg-column {
		flex-direction: column !important;
	}
	
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	
	.jc-lg-start {
		justify-content: flex-start !important;
	}
	
	.jc-lg-end {
		justify-content: flex-end !important;
	}
	
	.jc-lg-center {
		justify-content: center !important;
	}
	
	.jc-lg-between {
		justify-content: space-between !important;
	}
	
	.jc-lg-around {
		justify-content: space-around !important;
	}
	
	.jc-lg-evenly {
		justify-content: space-evenly !important;
	}
	
	.ai-lg-start {
		align-items: flex-start !important;
	}
	
	.ai-lg-end {
		align-items: flex-end !important;
	}
	
	.ai-lg-center {
		align-items: center !important;
	}
	
	.ai-lg-baseline {
		align-items: baseline !important;
	}
	
	.ai-lg-stretch {
		align-items: stretch !important;
	}
	
	.ac-lg-start {
		align-content: flex-start !important;
	}
	
	.ac-lg-end {
		align-content: flex-end !important;
	}
	
	.ac-lg-center {
		align-content: center !important;
	}
	
	.ac-lg-between {
		align-content: space-between !important;
	}
	
	.ac-lg-around {
		align-content: space-around !important;
	}
	
	.ac-lg-stretch {
		align-content: stretch !important;
	}
	
	.as-lg-auto {
		align-self: auto !important;
	}
	
	.as-lg-start {
		align-self: flex-start !important;
	}
	
	.as-lg-end {
		align-self: flex-end !important;
	}
	
	.as-lg-center {
		align-self: center !important;
	}
	
	.as-lg-baseline {
		align-self: baseline !important;
	}
	
	.as-lg-stretch {
		align-self: stretch !important;
	}
	
	.order-lg-first {
		order: -1 !important;
	}
	
	.order-lg-0 {
		order: 0 !important;
	}
	
	.order-lg-1 {
		order: 1 !important;
	}
	
	.order-lg-2 {
		order: 2 !important;
	}
	
	.order-lg-3 {
		order: 3 !important;
	}
	
	.order-lg-4 {
		order: 4 !important;
	}
	
	.order-lg-5 {
		order: 5 !important;
	}
	
	.order-lg-last {
		order: 6 !important;
	}
}

@media (min-width: 1200px) {
	.d-xl-inline {
		display: inline !important;
	}
	
	.d-xl-inline-block {
		display: inline-block !important;
	}
	
	.d-xl-block {
		display: block !important;
	}
	
	.d-xl-grid {
		display: grid !important;
	}
	
	.d-xl-table {
		display: table !important;
	}
	
	.d-xl-table-row {
		display: table-row !important;
	}
	
	.d-xl-table-cell {
		display: table-cell !important;
	}
	
	.d-xl-flex {
		display: flex !important;
	}
	
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
	
	.d-xl-none {
		display: none !important;
	}
	
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	
	.flex-xl-row {
		flex-direction: row !important;
	}
	
	.flex-xl-column {
		flex-direction: column !important;
	}
	
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	
	.jc-xl-start {
		justify-content: flex-start !important;
	}
	
	.jc-xl-end {
		justify-content: flex-end !important;
	}
	
	.jc-xl-center {
		justify-content: center !important;
	}
	
	.jc-xl-between {
		justify-content: space-between !important;
	}
	
	.jc-xl-around {
		justify-content: space-around !important;
	}
	
	.jc-xl-evenly {
		justify-content: space-evenly !important;
	}
	
	.ai-xl-start {
		align-items: flex-start !important;
	}
	
	.ai-xl-end {
		align-items: flex-end !important;
	}
	
	.ai-xl-center {
		align-items: center !important;
	}
	
	.ai-xl-baseline {
		align-items: baseline !important;
	}
	
	.ai-xl-stretch {
		align-items: stretch !important;
	}
	
	.ac-xl-start {
		align-content: flex-start !important;
	}
	
	.ac-xl-end {
		align-content: flex-end !important;
	}
	
	.ac-xl-center {
		align-content: center !important;
	}
	
	.ac-xl-between {
		align-content: space-between !important;
	}
	
	.ac-xl-around {
		align-content: space-around !important;
	}
	
	.ac-xl-stretch {
		align-content: stretch !important;
	}
	
	.as-xl-auto {
		align-self: auto !important;
	}
	
	.as-xl-start {
		align-self: flex-start !important;
	}
	
	.as-xl-end {
		align-self: flex-end !important;
	}
	
	.as-xl-center {
		align-self: center !important;
	}
	
	.as-xl-baseline {
		align-self: baseline !important;
	}
	
	.as-xl-stretch {
		align-self: stretch !important;
	}
	
	.order-xl-first {
		order: -1 !important;
	}
	
	.order-xl-0 {
		order: 0 !important;
	}
	
	.order-xl-1 {
		order: 1 !important;
	}
	
	.order-xl-2 {
		order: 2 !important;
	}
	
	.order-xl-3 {
		order: 3 !important;
	}
	
	.order-xl-4 {
		order: 4 !important;
	}
	
	.order-xl-5 {
		order: 5 !important;
	}
	
	.order-xl-last {
		order: 6 !important;
	}
}

@media (min-width: 1400px) {
	.d-xxl-inline {
		display: inline !important;
	}
	
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	
	.d-xxl-block {
		display: block !important;
	}
	
	.d-xxl-grid {
		display: grid !important;
	}
	
	.d-xxl-table {
		display: table !important;
	}
	
	.d-xxl-table-row {
		display: table-row !important;
	}
	
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	
	.d-xxl-flex {
		display: flex !important;
	}
	
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
	
	.d-xxl-none {
		display: none !important;
	}
	
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	
	.flex-xxl-row {
		flex-direction: row !important;
	}
	
	.flex-xxl-column {
		flex-direction: column !important;
	}
	
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	
	.jc-xxl-start {
		justify-content: flex-start !important;
	}
	
	.jc-xxl-end {
		justify-content: flex-end !important;
	}
	
	.jc-xxl-center {
		justify-content: center !important;
	}
	
	.jc-xxl-between {
		justify-content: space-between !important;
	}
	
	.jc-xxl-around {
		justify-content: space-around !important;
	}
	
	.jc-xxl-evenly {
		justify-content: space-evenly !important;
	}
	
	.ai-xxl-start {
		align-items: flex-start !important;
	}
	
	.ai-xxl-end {
		align-items: flex-end !important;
	}
	
	.ai-xxl-center {
		align-items: center !important;
	}
	
	.ai-xxl-baseline {
		align-items: baseline !important;
	}
	
	.ai-xxl-stretch {
		align-items: stretch !important;
	}
	
	.ac-xxl-start {
		align-content: flex-start !important;
	}
	
	.ac-xxl-end {
		align-content: flex-end !important;
	}
	
	.ac-xxl-center {
		align-content: center !important;
	}
	
	.ac-xxl-between {
		align-content: space-between !important;
	}
	
	.ac-xxl-around {
		align-content: space-around !important;
	}
	
	.ac-xxl-stretch {
		align-content: stretch !important;
	}
	
	.as-xxl-auto {
		align-self: auto !important;
	}
	
	.as-xxl-start {
		align-self: flex-start !important;
	}
	
	.as-xxl-end {
		align-self: flex-end !important;
	}
	
	.as-xxl-center {
		align-self: center !important;
	}
	
	.as-xxl-baseline {
		align-self: baseline !important;
	}
	
	.as-xxl-stretch {
		align-self: stretch !important;
	}
	
	.order-xxl-first {
		order: -1 !important;
	}
	
	.order-xxl-0 {
		order: 0 !important;
	}
	
	.order-xxl-1 {
		order: 1 !important;
	}
	
	.order-xxl-2 {
		order: 2 !important;
	}
	
	.order-xxl-3 {
		order: 3 !important;
	}
	
	.order-xxl-4 {
		order: 4 !important;
	}
	
	.order-xxl-5 {
		order: 5 !important;
	}
	
	.order-xxl-last {
		order: 6 !important;
	}
}

@media print {
	.d-print-inline {
		display: inline !important;
	}
	
	.d-print-inline-block {
		display: inline-block !important;
	}
	
	.d-print-block {
		display: block !important;
	}
	
	.d-print-grid {
		display: grid !important;
	}
	
	.d-print-table {
		display: table !important;
	}
	
	.d-print-table-row {
		display: table-row !important;
	}
	
	.d-print-table-cell {
		display: table-cell !important;
	}
	
	.d-print-flex {
		display: flex !important;
	}
	
	.d-print-inline-flex {
		display: inline-flex !important;
	}
	
	.d-print-none {
		display: none !important;
	}
}

.ant-select-selection-item {
  margin-right: 30px !important;
}

.ant-picker-input > input {
  width: auto;
}