@import "../../../static/styles/inc/global/variables";

#mobile-menu {
	background: #fff;
	bottom: 0;
	display: none;
	left: 0;
	padding: 30px 15px 20px;
	position: absolute;
	right: 0;
	top: 62px;
	z-index: 100;
	
	a {
		color: $colorText;
		font-size: 20px;
		margin-bottom: 15px;
	}
	
	.language {
		font-size: 15px;
		position: relative;
		
		.selected {
			background: url("../../../static/imgs/icon/chevron-down.svg") 100% 50% no-repeat;
			background-size: 8px auto;
			cursor: pointer;
			padding-right: 15px;
		}
		
		.list {
			background: #fff;
			border-radius: 3px;
			bottom: 100%;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			transform: translate3d(0, -20px, 0);
			transition: all .3s;
			visibility: hidden;
			width: 100%;
			
			&-item {
				cursor: pointer;
				padding: 1px 6px;
				
				&:hover {
					background: $colorAccent;
					color: #fff;
				}
			}
		}
		
		&._active {
			.list {
				opacity: 1;
				transform: translate3d(0, -5px, 0);
				visibility: visible;
			}
		}
	}
	
	&._visible {
		display: flex;
	}
}
