.tooltip-icon {
	align-items: center;
	background: #eff7ff;
	border-radius: 100%;
	cursor: pointer;
	display: inline-flex;
	font-size: 13px;
	font-weight: 500;
	height: 16px;
	justify-content: center;
	width: 16px;
}

.tippy {
	&-content {
		border-radius: 4px;
		box-shadow: 2px 2px 8px rgba(28, 33, 49, 0.2);
		color: $colorText2;
		font-size: 13px;
		padding: 10px;
	}
	
	&-arrow {
		z-index: 10;
	}
}

.tippy-box[data-theme~="light"] {
	background: #fff;
	
	&[data-placement^="top"] {
		& > .tippy-arrow::before {
			border-top-color: #fff;
		}
	}
	
	&[data-placement^="bottom"] {
		& > .tippy-arrow::before {
			border-bottom-color: #fff;
		}
	}
	
	&[data-placement^="left"] {
		& > .tippy-arrow::before {
			border-left-color: #fff;
		}
	}
	
	&[data-placement^="right"] {
		& > .tippy-arrow::before {
			border-right-color: #fff;
		}
	}
}
