@media (max-width: $mediaMd) {
	.page-header-sort-btn {
		background: #fff !important;
		border: none;
		border-radius: 100%;
		box-shadow: 0 2px 5px -2px rgba(28, 33, 49, 0.25);
		height: 36px;
		padding: 0;
		width: 36px;
		
		* {
			fill: $colorText;
		}
	}
}
