@import "../../../static/styles/inc/global/variables";

#lk-side-menu {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	transition: all .2s;
	flex: 0 0 240px;
	width: 240px;
	
	.trns {
		transition: all .2s;
	}
	
	.logo {
		margin: 16px 24px;
		width: 90px;
		
		img {
			width: 100%;
		}
	}
	
	.menu {
		.link {
			align-items: center;
			color: inherit;
			cursor: pointer;
			display: flex;
			position: relative;
			
			&:hover,
			&.active {
				background: rgba(255, 255, 255, 0.2);
			}
		}
		
		&-head {
			.chevron {
				height: 16px;
				object-fit: contain;
				right: 12px;
				width: 16px;
			}
		}
		
		.has-submenu {
			ul {
				display: none;
				
				a {
					font-weight: 300;
					padding: 6px 24px 6px 40px
				}
			}
			
			&.active {
				margin-bottom: 10px;
				
				.menu-head {
					.chevron {
						transform: rotate(180deg) translate3d(0, 50%, 0) !important;
					}
				}
				
				ul {
					display: block;
				}
			}
		}
		
		&-head {
			padding: 10px 50px 10px 24px;
			
			img {
				height: 22px;
				margin-right: 7px;
				object-fit: contain;
				width: 22px;
			}
		}
	}
	
	&._small {
		.menu {
			.has-submenu {
				ul {
					position: absolute;
					z-index: 1;
					top: 0;
					left: 100%;
					width: 180px;
					background: $colorPrimary;
					padding: 6px 0;
					
					a {
						font-size: 14px;
						padding: 6px 14px;
					}
				}
				
				&:hover {
					ul {
						display: block !important;
					}
				}
				
				&.active {
					margin-bottom: 0;
					
					ul {
						display: none !important;
					}
				}
			}
		}
		
		@media (min-width: $mediaXl) {
			flex: 0 0 100px;
			width: 100px;
			
			.logo {
				width: 52px;
			}
			
			.menu-link-text,
			.chevron {
				display: none !important;
			}
			
			.menu-head {
				justify-content: center;
				padding-left: 0;
				padding-right: 0;
				
				img {
					margin-right: 0;
				}
			}
		}
	}

	@media (max-width: $mediaXl) {
		transform: translate3d(-240px, 0, 0);
		overflow: visible;
		position: fixed;
		top: 0;
		bottom: 0;
		z-index: 1000;
		
		&._active {
			transform: translate3d(0, 0, 0);
		}
	}
}
