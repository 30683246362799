table {
	width: 100%;
}

.cmp-table {
	thead {
		color: $colorText2;
		font-size: 11px;
		
		tr {
			background: #fff;
		}
		
		td {
			.ant {
				&-select {
					&-selector {
						height: 32px !important;
						overflow: auto;
					}
				}
			}
		}
	}
	
	.form-field {
		min-width: 80px;
	}
	
	th,
	td {
		font-size: 12px;
		min-width: 30px;
		padding: 8px;
		vertical-align: middle;
	}
	
	th {
		border-bottom: 2px solid #dbdde3;
		text-align: left;
		vertical-align: bottom;
	}
	
	td {
		border-bottom: 2px solid #dbdde3;
	}
	
	tr {
		&._bg-gray {
			background: #e8e8e8;
		}
		
		&:hover {
			background: #f3f3f3;
		}
	}
	
	.status-success {
		background: #effff1
	}
	
	.status-error {
		background: #ffe0e7
	}
	
	&._fill {
		background-color: #eee;
		border: 1px solid #d2d6de;
		font-size: 14px;
		
		th,
		td {
			border: 1px solid #d2d6de;
			color: $colorText;
		}
	}
}
