.cmp-card-footbal-vs {
	@media (max-width: $mediaXxl) {
		.h6 {
			font-size: 10px;
		}
		
		.h2 {
			font-size: 28px;
		}
	}
	
	@media (max-width: $mediaLg) {
		.h2 {
			font-size: 20px;
		}
	}
}
