.cmp-card_event {
	.club-img {
		height: 120px;
		width: 120px;
	}
	
	.line {
		background: #cacdd7;
		bottom: 0;
		top: 0;
		width: 1px;
	}
	
	@media (max-width: $mediaMd) {
		.club-img {
			height: 110px;
			width: 110px;
		}
	}
}
