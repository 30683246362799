input,
select,
textarea,
button {
  font-family: $fontName;
}

.ant {
  &-select {
    width: 100%;

    &-selection-search {
      margin-inline-start: 0 !important;
    }

    &-selector {
      border-radius: 4px !important;
      min-height: 36px !important;
      padding: 2px 11px !important;
    }

    &-selection {
      &-search {
        input {
          height: 100%;
        }
      }

      &-item {
        line-height: 26px !important;
        margin-bottom: 3px !important;
        margin-top: 3px !important;

        &-content {
          line-height: 22px !important;
        }

        &-remove {
          align-items: center;
          display: flex !important;
          justify-content: center;
        }
      }
    }
  }

  &-picker {
    border-radius: 4px !important;
    padding: 6px 28px 6px 11px !important;
    width: 100%;
  }
}

.form {
  &-field {
    display: block;
    margin-bottom: 16px;

    .icon {
      bottom: 0;
      position: absolute;
      top: 0;
      width: 36px;
      z-index: 100;

      &._right {
        right: 0;
      }

      &._left {
        left: 0;
      }
    }

    .date-picker {
      padding-bottom: 0;
      padding-top: 0;
    }

    &._icon-left {
      .form-input {
        padding-left: 36px;
      }
    }

    &._icon-right {
      .form-input {
        padding-right: 36px;
      }
    }
  }

  &-label {
    text-align: left;
    color: #828d99;
    display: block;
    font-size: 10px;
    letter-spacing: 1px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  &-input {
    background: #fff;
    border: 1px solid #dbdde3;
    border-radius: 4px;
    color: $colorText;
    display: block;
    font-size: 14px;
    height: 36px;
    padding: 7px 12px 8px;
    text-align: left !important;
    width: 100%;

    &._textarea {
      min-height: 140px;
    }

    &[type='password'] {
      padding-right: 36px;
    }

    &:focus {
      border-color: #828d99;
    }

    &._error {
      background: #fff url('../../../imgs/icon/error.svg') calc(100% - 10px) 50% no-repeat;
      border-color: $colorAccent;
      padding-right: 36px;
    }

    &._success {
      background: #fff url('../../../imgs/icon/success-check.svg') calc(100% - 10px) 50% no-repeat;
      border-color: $colorGreen;
      padding-right: 36px;
    }
  }

  &-error {
    color: red;
  }
}

.select {
  cursor: pointer;
  padding: 0;
  position: relative;

  &:after {
    background: url('../../../imgs/icon/arrowDown.svg') no-repeat 50% 50%;
    content: '';
    height: 36px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s;
    width: 36px;
  }

  .selected {
    display: block;
    padding: 6px 36px 9px 12px;

    span {
      @include text-overflow(1);
    }
  }

  .list {
    background: #fff;
    border: 1px solid #dbdde3;
    border-radius: 0 0 4px 4px;
    left: -1px;
    opacity: 0;
    position: absolute;
    right: -1px;
    top: calc(100% + 15px);
    transition: all 0.3s;
    visibility: hidden;
    z-index: 100;

    .inner-list {
      max-height: 200px;
    }

    &-val {
      font-size: 14px;
      line-height: 1.3;
      padding: 7px 15px;

      &:hover {
        background: $colorPrimary;
        color: #fff;
      }
    }
  }

  &._active {
    border-radius: 4px 4px 0 0;

    &:after {
      transform: rotate(180deg);
    }

    .list {
      opacity: 1;
      transform: translate3d(0, -15px, 0);
      visibility: visible;
    }
  }
}

.checkbox {
  background: #fff;
  border: 1px solid #dbdde3;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  width: 18px;

  &:checked {
    background: $colorGreen url('../../../imgs/icon/checkbox.svg') 50% no-repeat;
    background-size: 12px auto;
    border-color: $colorGreen;
  }

  &._radio {
    border: none;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #dbdde3;
    height: 16px;
    width: 16px;

    &:checked {
      background: $colorPrimary;
      border: 4px solid #fff;
      box-shadow: 0 0 0 1px #dbdde3;
    }
  }
}

.input-select {
  .form-input {
    border-radius: 4px 0 0 4px;
  }

  select {
    background: url('../../../imgs/icon/arrowDown.svg') no-repeat calc(100% - 8px) 50%;
    background-size: 12px auto;
    border: 1px solid #dbdde3;
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding: 6px 22px 6px 12px;
  }
}

.image-upload-block {
  background: #f4f4f4;
  border-radius: 4px;
  min-height: 116px;
}

.arrowNone::-webkit-outer-spin-button,
.arrowNone::-webkit-inner-spin-button {
  appearance: none;
}
